// Register `hName`, `hProperties` types, used when turning markdown to HTML:
/// <reference types="mdast-util-to-hast" />
// Register directive nodes in mdast:
/// <reference types="mdast-util-directive" />

import { visit } from 'unist-util-visit'

// This plugin is turn `::youtube` into iframes.
export function youtubePlugin() {
  /**
   * @param {import('mdast').Root} tree
   *   Tree.
   * @param {import('vfile').VFile} file
   *   File.
   * @returns {undefined}
   *   Nothing.
   */
  return (tree, file) => {
    visit(tree, function (node) {
      if (
        node.type === 'containerDirective' ||
        node.type === 'leafDirective' ||
        node.type === 'textDirective'
      ) {
        if (node.name !== 'youtube') return

        const data = node.data || (node.data = {})
        const attributes = node.attributes || {}
        const id = attributes.id

        if (node.type === 'textDirective') {
          file.fail(
            'Unexpected `:youtube` text directive, use two colons for a leaf directive',
            node
          )
        }

        if (!id) {
          file.fail('Unexpected missing `id` on `youtube` directive', node)
        }

        const title = node.children[0].value
        const width = attributes.width
        const height = attributes.height
        const allow =
          attributes.allow ||
          'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        const referrerpolicy =
          attributes.referrerpolicy || 'strict-origin-when-cross-origin'

        data.hName = 'iframe'
        data.hProperties = {
          title: title,
          src: 'https://www.youtube-nocookie.com/embed/' + id,
          width: width,
          height: height,
          frameBorder: 0,
          allow: allow,
          referrerpolicy: referrerpolicy,
          allowFullScreen: true,
          loading: 'lazy'
        }
      }
    })
  }
}
