import React from 'react'
import clsx from 'clsx'
import { Icon } from '@sakura-ui/core'
import { localizedPath } from 'helper'

// 対応言語一覧
interface Lang {
  code: string
  title: string
}

const allLangs: Lang[] = [
  { code: 'en', title: 'English' },
  { code: 'fr', title: 'Français' },
  { code: 'de', title: 'Deutsch' },
  { code: 'es', title: 'Español' },
  { code: 'it', title: 'Italiano' },
  { code: 'ja', title: '日本語' },
  { code: 'ko', title: '한국어' },
  { code: 'zh-cmn-hans', title: '简体中文' },
  { code: 'zh-cmn-hant', title: '繁體中文' }
]

export interface LangSelectorProps
  extends React.ComponentPropsWithRef<'details'> {
  lang: string
  langs: string[]
  pagePath: string
}

export const LangSelector = ({
  lang,
  langs,
  pagePath,
  className,
  ...rest
}: LangSelectorProps) => {
  const dispLangs = allLangs.filter((lang) => langs.includes(lang.code))

  const style = `
    cursor-pointer
    relative
  `

  const styleSummary = `
    list-none
    [&::-webkit-details-marker]:hidden
    outline-offset-2
    hover:text-sea-1000
    focus-visible:outline
    focus-visible:outline-2
    focus-visible:outline-wood-600
  `

  const styleSelector = `
    absolute
    top-8
    right-0
    font-normal
    py-1
    bg-white
    rounded-md
    border
    border-sumi-500
  `

  const styleList = `
    px-6
    leading-8
    hover:bg-sumi-200
    relative
    whitespace-nowrap
  `

  return (
    <details className={style} {...rest}>
      <summary className={clsx(styleSummary)}>
        <Icon icon="language" className="mr-1" />
        Language
        <Icon icon="expand_more" />
      </summary>
      <ul className={styleSelector}>
        {dispLangs.map((dispLang) => (
          <li className={styleList} key={dispLang.code}>
            {lang === dispLang.code ? (
              <Icon
                className="text-base absolute top-[4px] left-1.5"
                icon="check"
              />
            ) : (
              ''
            )}
            <a href={localizedPath(pagePath, dispLang.code)}>
              {dispLang.title}
            </a>
          </li>
        ))}
      </ul>
    </details>
  )
}
