// Register `hName`, `hProperties` types, used when turning markdown to HTML:
/// <reference types="mdast-util-to-hast" />
// Register directive nodes in mdast:
/// <reference types="mdast-util-directive" />

import { h } from 'hastscript'
import { visit } from 'unist-util-visit'

// This plugin is to turn `::linkButton` into divs, passing arbitrary
// attributes.
export function linkButtonPlugin() {
  /**
   * @param {import('mdast').Root} tree
   *   Tree.
   * @returns {undefined}
   *   Nothing.
   */
  return (tree) => {
    visit(tree, (node) => {
      if (node.type === 'textDirective') {
        if (node.name !== 'link-button') return

        const data = node.data || (node.data = {})
        const tagName = 'a'

        data.hName = tagName
        node.attributes['data-node'] = node.name

        data.hProperties = h(tagName, node.attributes || {}).properties
      }
    })
  }
}
