import clsx from 'clsx'
import React from 'react'
import { Card, CardHeader, CardBody, Icon } from '@sakura-ui/core'

interface LinkContextType {
  href: string
}

const LinkContext = React.createContext<LinkContextType>({ href: '' })

export interface LinkCardProps extends React.ComponentProps<'article'> {
  href: string
}

export const LinkCard = ({
  href,
  className,
  children,
  ...rest
}: LinkCardProps) => {
  const styleLink = `
    outline-offset-4
    rounded-2xl
    sm:rounded-3xl
    focus:outline-2
    focus:outline-wood-600
  `

  const styleHover = `
    hover:text-sea-1000
    hover:border-sea-1000
  `

  const isExternal = href?.startsWith('https://')

  return (
    <LinkContext.Provider value={{ href: href }}>
      <Card className={clsx(styleHover, className)}>
        <a
          className={clsx(styleLink)}
          href={href}
          target={isExternal ? '_blank' : ''}
          {...rest}
        >
          {children}
        </a>
      </Card>
    </LinkContext.Provider>
  )
}

export interface LinkCardHeaderProps extends React.ComponentProps<'div'> {}

export const LinkCardHeader = ({
  className,
  children
}: LinkCardHeaderProps) => {
  const { href } = React.useContext(LinkContext)

  const styleHeading = `
    flex
    justify-between
    items-center
  `

  const isExternal = href.startsWith('https://')

  return (
    <CardHeader className={clsx(className, styleHeading)}>
      {href === '' ? (
        children
      ) : (
        <>
          <span>
            {children}
            {isExternal ? (
              <Icon className="text-base ml-1 font-light" icon="open_in_new" />
            ) : (
              ''
            )}
          </span>
          {isExternal ? (
            ''
          ) : (
            <Icon className="text-base-sm !font-medium" icon="arrow_forward" />
          )}
        </>
      )}
    </CardHeader>
  )
}

export interface ServiceCardProps extends React.ComponentProps<'article'> {
  title: string
  url: string
  body: string
}

export const ServiceCard = ({
  className,
  url,
  title,
  body
}: ServiceCardProps) => {
  const styleHeading = `
    flex
    justify-between
    items-center
  `

  return (
    <LinkCard href={url} className={clsx(className)}>
      <LinkCardHeader className={styleHeading}>{title}</LinkCardHeader>
      <CardBody>{body}</CardBody>
    </LinkCard>
  )
}
