// Register `hName`, `hProperties` types, used when turning markdown to HTML:
/// <reference types="mdast-util-to-hast" />
// Register directive nodes in mdast:
/// <reference types="mdast-util-directive" />

import { h } from 'hastscript'
import { visit } from 'unist-util-visit'

// This plugin is to turn `::linkButton` into divs, passing arbitrary
// attributes.
export function gridPlugin() {
  /**
   * @param {import('mdast').Root} tree
   *   Tree.
   * @returns {undefined}
   *   Nothing.
   */
  return (tree) => {
    visit(tree, (node) => {
      if (node.type !== 'containerDirective') {
        return
      }
      if (!node.name.startsWith('grid-cols-')) {
        return
      }

      if (node.attributes?.as === 'list') {
        node.attributes.as = undefined
        node.attributes['data-behavior'] = 'list'
      }

      const gridNum = node.name.split('-').pop()

      // 文字列の埋め込みだとTailwindがCSSを生成してくれない
      const gridClass: { [key: number]: string } = {
        1: 'md:grid-cols-1',
        2: 'md:grid-cols-2',
        3: 'md:grid-cols-3',
        4: 'md:grid-cols-4',
        5: 'md:grid-cols-5',
        6: 'md:grid-cols-6',
        7: 'md:grid-cols-6',
        8: 'md:grid-cols-6',
        9: 'md:grid-cols-6',
        10: 'md:grid-cols-6',
        11: 'md:grid-cols-6',
        12: 'md:grid-cols-12'
      }

      const data = node.data || (node.data = {})
      const tagName = 'div'

      data.hName = tagName

      node.attributes.class = `flex flex-col md:grid ${gridClass[gridNum]} gap-8`

      data.hProperties = h(tagName, node.attributes || {}).properties
    })
  }
}
