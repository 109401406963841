import React from 'react'
import clsx from 'clsx'
import { H1 } from '@sakura-ui/core'
import { BreadcrumbsItem, Breadcrumbs, LangSelector } from '../molecules'
import { FooterLogo } from '../organisms'
import { UserInsight } from '../atoms'
import { usePageContext } from '../context/PageContext'
import { useTranslation } from 'react-i18next'
import { localizedPath } from 'helper'
import { useSimpleAuthenticator } from '../hooks'

export interface ServiceLayoutProps {
  title: string
  serviceId: string
  serviceName: string
  items?: BreadcrumbsItem[]
  children: React.ReactNode
}

export const ServiceLayout = ({
  title,
  serviceId,
  serviceName,
  items,
  children
}: ServiceLayoutProps) => {
  const { t } = useTranslation()
  const { lang, langs, originalPath } = usePageContext()

  const authenticated = useSimpleAuthenticator()
  if (!authenticated) {
    return <></>
  }

  const style = `
    min-h-screen
    text-sumi-900
    text-base
    font-medium
    leading-8
  `

  const containerStyle = `
    max-w-[336px]
    sm:max-w-screen-sm
    md:max-w-screen-md
    lg:max-w-screen-lg
    xl:max-w-[1120px]
    mx-auto
  `

  const breadcrumbsStyle = `
    my-12
    rounded-3xl
    py-2
    px-4
    bg-sumi-100
  `

  return (
    <div className={style}>
      <header className={clsx('max-w-[1120px] mx-auto')}>
        <div className="p-6 xl:px-0 flex justify-between">
          <a href={localizedPath(`/${serviceId}`, lang)}>
            <p className="!font-bold text-lgm sm:text-lg">{serviceName}</p>
          </a>
          {langs.length > 1 ? (
            <nav>
              <ul className="flex gap-8">
                <li className="">
                  <LangSelector
                    lang={lang}
                    langs={langs}
                    pagePath={originalPath}
                  />
                </li>
              </ul>
            </nav>
          ) : (
            ''
          )}
        </div>
      </header>
      <main className={containerStyle}>
        <article>
          {title !== '' ? <H1>{title}</H1> : ''}
          {children}
          {items ? (
            <Breadcrumbs
              className={breadcrumbsStyle}
              items={items.map((item) => {
                if (item.href) {
                  item.href = localizedPath(item.href, lang)
                }
                return item
              })}
            />
          ) : (
            ''
          )}
        </article>
      </main>
      <footer className="mt-24 text-base-sm">
        <p className="mb-8 text-center text-base !font-bold">
          <a href="/">{t('global.title')}</a>
        </p>
        <FooterLogo />
      </footer>
      <UserInsight />
    </div>
  )
}
