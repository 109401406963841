// Register `hName`, `hProperties` types, used when turning markdown to HTML:
/// <reference types="mdast-util-to-hast" />
// Register directive nodes in mdast:
/// <reference types="mdast-util-directive" />

import { h } from 'hastscript'
import { visit } from 'unist-util-visit'

// This plugin is to turn `::linkButton` into divs, passing arbitrary
// attributes.
export function faqPlugin() {
  /**
   * @param {import('mdast').Root} tree
   *   Tree.
   * @returns {undefined}
   *   Nothing.
   */
  return (tree) => {
    visit(tree, (node) => {
      if (node.type !== 'containerDirective') {
        return
      }
      if (node.name !== 'faq') {
        return
      }

      const data = node.data || (node.data = {})
      const tagName = 'dl'

      data.hName = tagName

      data.hProperties = h(tagName, node.attributes || {}).properties

      node.children.forEach((child) => {
        if (child.name !== 'faq-q' && child.name !== 'faq-a') {
          return
        }
        const data = child.data || (child.data = {})
        const tagName = child.name === 'faq-q' ? 'dt' : 'dd'

        data.hName = tagName

        data.hProperties = h(tagName, child.attributes || {}).properties
      })
    })
  }
}
